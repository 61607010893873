.trustpilot-wrapper {
  position: relative;
}

.trustpilot-wrapper.fluid {
  width: 100%;
}

.trustpilot-carousel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 200px;
  z-index: 2;
  opacity: 0.5;
}

.trustpilot-list {
  position: absolute;
  left: 0;
  top: 50px;
  height: 45px;
  width: 100%;
  z-index: 2;
  opacity: 0.5;
}

.trustpilot-mini {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0.5;
}

.trustpilot-horizontal {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0.5;
}

@media (max-width: 580px) {
  .trustpilot-carousel {
    width: 100%;
  }
}
