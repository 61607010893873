.logo-container {
  width: 144px;
  height: 25px;
}

.logo-container .logo {
  height: auto;
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
}

@media only screen and (max-width: 767px) {
  .logo-container {
    flex: 0 0 102px;
    height: auto;
  }
}
