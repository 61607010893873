.toastr {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 40px 10px 10px;
  background-color: #323232;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transform: translateY(-10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: space-between;
}

.toastr.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.toastr.success {
  background-color: #4CAF50;
}

.toastr.error {
  background-color: #e74c3c;
}

.toastr .toastr-text-message {
  width: 100%;
  color: white;
  padding: 10px;
}

.toastr-close-icon {
  margin-top: -1.5rem;
  margin-right: -1.5rem;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.2s;
}

.toastr-close-icon:hover {
  color: #ccc;
}