.response-tap-header {
  position: relative;
  overflow: hidden;
}

.phone-text {
  padding-right: 8px;
}

.phone-icon {
  display: none;
}
.kCUnUM{
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .phone-text {
    display: none;
  }

  .phone {
    opacity: 0;
    position: absolute;
    top: 8px;
    right: 11px;
    width: 89px;
    height: 32px;
    z-index: 2;
    overflow: hidden;
  }

}
