#modal-root .exit-popup-modal-overlay {
  z-index: 35;
}

#date-picker-modal {
  z-index: 100000000;
  position: relative;
}

#modal-root .exit-popup-modal {
  padding: 16px;
  max-width: 1110px;
  width: auto;
  overflow: initial;
}

.exit-popup-modal-btn {
  position: relative;
}

.exit-popup-modal-btn>div {
  right: 10px;
  top: 10px;
}

.exit-popup-modal .exit-popup-modal-content {
  background-color: #e9f6ff;
  padding: 28px 28px 0;
}

.exit-popup-headline {
  margin: 1vh auto 3vh;
}

.exit-popup-modal-content h1 {
  font-size: 34px;
  line-height: 42px;
  font-weight: bold;
  margin: 0;
}

.exit-popup-modal-content h2 {
  font-size: 34px;
  line-height: 42px;
  font-weight: bold;
  margin: 0;
}

.exit-popup-modal-content h2 span {
  font-weight: normal;
}

.exit-popup-modal-trustpilot-holder {
  padding: 28px;
  margin: 18px -28px 0;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {

  .exit-popup-modal-content h1,
  .exit-popup-modal-content h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 550px) {
  .exit-popup-modal-overlay .exit-popup-modal {
    max-height: calc(100vh - 10px);
  }

  .exit-popup-modal {
    padding: 10px;
  }

  .exit-popup-modal .exit-popup-modal-content {
    padding: 10px 10px 0;
  }

  .exit-popup-modal-content h1,
  .exit-popup-modal-content h2 {
    font-size: 20px;
    line-height: 26px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .exit-popup-modal-trustpilot-holder {
    padding: 10px;
    margin: 10px -10px 0;
  }
}