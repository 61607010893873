.Toastify__toast-container {
    width: 50%;
}

.Toastify__toast-container--bottom-center {
    bottom: 10%;
}

.Toastify__toast--success {
    color: #000;
}

@media screen and (max-width: 768px){
    .Toastify__toast-container {
        width: 90%;
    }
}

@media screen and (max-width: 480px){
    .Toastify__toast-container {
        left : 20px;
    }
}
