/* RESET STYLES*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* Colors*/
:root {
  --white-color: #ffffff;
  --darkWhite-color: #fafafa;
  --red-color: #ab0017;
  --lightBlue-color: #edf5fd;
  --darkBlue-color: #337ab7;
  --lightGreen-color: #59cdb8;
  --darkGreen-color: #45917f;
  --grey-color: #929292;
  --gray1-color: #cccccc;
  --lightGrey-color: #c6c6c6;
  --darkGrey-color: #4e4e4e;
}

body {
  min-width: 360px;
  font-family: Roboto;
}

a {
  color: unset;
  text-decoration: none;
}

/* Temporary solution */
@media screen and (min-width: 768px) {
  .payment-confirmation-page {
    display: flex;
    justify-content: space-around;
  }
}

/* iPhone fix zoom*/
@media only screen and (width: 320px) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  body {
    -webkit-transform: scale(0.667);
    -webkit-transform-origin: top right;
    position: absolute;
    right: 0;
    top: 0;
    width: 480px;
  }
}

.underline {
  text-decoration: underline;
}

.clickable {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .clickable {
    pointer-events: auto;
  }
}
