.flex-box {
  display: flex;
}

/* flex direction */
.flex-box.direction-row {
  flex-direction: row;
}

.flex-box.direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-box.direction-column {
  flex-direction: column;
}

.flex-box.direction-column-reverse {
  flex-direction: column-reverse;
}

/* justify content */
.flex-box.justify-content-flex-start {
  justify-content: flex-start;
}

.flex-box.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-box.justify-content-space-between {
  justify-content: space-between;
}

.flex-box.justify-content-center {
  justify-content: center;
}

/* align items */
.flex-box.align-items-flex-start {
  align-items: flex-start;
}

.flex-box.align-items-flex-end {
  align-items: flex-end;
}

.flex-box.align-items-center {
  align-items: center;
}

/* align-content */
.flex-box.align-content-center {
  align-content: center;
}

.flex-box.align-content-flex-start {
  align-content: flex-start;
}

.flex-box.align-content-flex-end {
  align-content: flex-end;
}



@media screen and (min-width: 1400px) {
  .flex-box.loginLogoutBtn-wrapper {
    display: none
  }
}