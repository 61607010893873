.content {
  background-color: #EFF0F6;
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .content {
    padding: 0 15px 15px 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px){
  .content{
    padding: 0 10px;
  }
}
