.header-wrapper {
  position: relative;
  z-index: 7;
  background-color: #FFFFFF;
  box-shadow: 0 1px 0 0 rgba(213,213,213,0.5);
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;
}

.placeholder{
  max-width: 230px;
  min-width: 100px;
}

@media only screen and (max-width: 1024px){
  .header{
    padding: 0 15px;
  }
}

  @media only screen and (max-width: 767px) {
  .header {
    height: 50px;
  }
}
